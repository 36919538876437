.Patient-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.padd-0 {
  padding: 0px !important;
}


.mid-head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.mid-head h2 {
  color: #2b3582;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 21px;
  margin: 10px 0px 0px 0px;
}


.btn-md-blue {
  background: #444D99;
  color: #fff;
  text-align: center;
  font-size: 15.86px;
  line-height: 16.78px;
  font-weight: 600;
  padding: 13px 15px;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.btn-md-blue:hover {
  background: #000;

}

.btn-md-blue img {
  margin-right: 5px;
}



.btn-small {
  cursor: pointer;
  transition: all 0.8s ease 0s;
  border: 1px #fff solid;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: 13.86px;
  line-height: 16.78px;
  font-weight: 600;
  padding: 8px 7px;
  margin-top: 0px;
  margin-right: 5px;
  height: 36px;
  width: 36px;
}

.greenbg {
  background-image: linear-gradient(#5EDE82, #3A9C56);
}

.yellowbg {
  background-image: linear-gradient(#FFC200, #FFC200);
}

.redbg {
  background-image: linear-gradient(#F36C6C, #D72A2A);
}



.btn-small:hover {
  background-image: linear-gradient(#444D99, #444D99);

}



.input-control {
  background: #ffffff;
  border: 1px #CDCDCD solid;
  border-radius: 10px;
  padding: 12px 12px;
  color: #444D99;
  width: 100%;
  font-size: 14px;
  font-family: "Inter";
}

.input-control::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #CECECE;
}


.view-btn-padd {
  padding: 2px 6px !important;
  margin: 0px 0px 0px 10px;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.text-color {
  color: #17A554 !important;
}



.blue {
  color: #6253D1 !important
}




.Patient-heading {
  display: flex;
  justify-content: flex-start;
  height: auto;
  column-gap: 10px;
}

.Patient-heading h2 {
  color: #2B3582;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 21px;
  margin: 10px 0px 0px 0px;
}



.pagination-col {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.pagination-col .lefttext p {
  font-size: 16px;
  color: #808080;
}


.PatientProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(/public/icons-images/PatientProfile-bg.svg);
  background-size: 100% 100;
  background-repeat: no-repeat;
  min-height: 298px;
  flex-direction: column;
}

.PatientProfile img {
  width: 121px;
  height: 121px;
  margin: 0px;
  border: 2px #444D99 solid;
  border-radius: 50%;
  padding: 2px;
}


.PatientProfile h2 {
  color: #000000;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 28.8px;
  margin: 23px 0px 0px 0px;
}

.PatientProfile p {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.238px;
  line-height: 21px;
  margin: 10px 0px 0px 0px;

}

.PatientProfile img {
  margin: 10px 0px 0px 0px;
}

.PatientProfilemain-bg {
  background: #F2F4F1;
  padding-bottom: 40px;
  padding-top: 20px;

}

.PatientProfileBox {
  margin-top: -50px;
  background: #fff;
  min-height: 200px;
}


.PatientProfileBox .left {
  padding: 15px 30px;
  border-bottom: 2px #F2F4F1 solid;
  border-right: 2px #F2F4F1 solid;
}


.left .form-group {
  margin-bottom: 10.57px !important;
  position: relative;
}

.left .form-group .input-control {
  background: #ffffff;
  border: none;
  border-bottom: 1px #D9D9D9 solid !important;
  border-radius: 0px !important;
  padding: 15px 10px !important;
  color: #444d99;
  width: 100%;
  font-size: 14px;
  font-family: "Lato";
  height: 22px;
}

.left .form-group label {
  font-size: 16px;
  font-family: "Lato";
  color: #000000;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 0px;
}

.left .form-group .input-control::placeholder {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
}

.left .form-group .size {
  font-size: 14px;
}

.left .form-group p img {
  width: 30px;
  height: 30px;
  margin: 5px 15px 0px 0px;
}

.PatientProfileBox .left h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
}


.PatientProfileBox .left h4 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  border: 1px #D9D9D9 solid;
  padding: 5px 10px
}


.PatientProfileBox .right {
  padding: 15px 30px 0px 20px;
  border-bottom: 2px #F2F4F1 solid;

}


.PatientProfileBox .right h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 10px 10px 0px 10px;
}

.PatientProfileBox .right p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.2px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 0px 0px 10px 30px;
}

.PatientProfileBox .right .map {
  margin: 20px 0px 0px 0px;
}

.PatientProfileBox .bottom {
  padding: 15px 30px 20px 20px;
  border-left: 2px #F2F4F1 solid;
}

.PatientProfileBox .bottom h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 0px 10px 0px 10px;
  text-transform: uppercase;
  margin: 0px 0px 6px 0px;
}

.PatientProfileBox .bottom h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #000000;
  padding: 0px 10px 0px 10px;
  margin: 6px 0px px 0px;
}
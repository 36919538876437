.checkbox-switch {
  /* transform: scale(1.5); */
  width: 60px !important;
  height: 25px !important;
  background: linear-gradient(180deg, #62e587 0%, #379652 100%);
  /* linear-gradient(0deg, #3b9d57, #3b9d57), */

  border: 2.5px solid rgb(87, 182, 87);

  border-radius: 22.1731px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72);
  transition: 300ms ease-in-out;
}

.checkbox--on {
  flex-direction: row;
  background: linear-gradient(180deg, #e56262 0%, #d21f1f 100%);
  /* linear-gradient(0deg, #871212, #871212), */
  border: 1.23184px solid #861212;
  color: white;
  transition: 300ms ease-in-out;
}

.checkbox--on .checkbox__ball {
  width: 21.97px;
  height: 22.15px;
  background: #ffffff;
  box-shadow: 0px 2.86105px 2.86105px rgba(0, 0, 0, 0.1);
  transition: 300ms ease-in-out;
}

.checkbox__ball {
  background: whitesmoke;
  box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72);
  width: 20px;
  height: 100%;
  border-radius: 50%;
  transition: 300ms ease-in-out;
}

/* Calendar CSS */
/* Customize the entire calendar container (optional) */
.custom-calendar {
  width: 100%;
}

.marked-date {
  background-color: #aaaaaa69 !important; /* Use your desired greyish color */
  color: rgba(0, 0, 0, 0.151) !important;
}

.react-calendar__navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-calendar__navigation > button {
  all: unset;
  text-align: center;
  padding: 10px 0px;
  font-size: 1rem;
  cursor: pointer;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-weight: 700 !important;
  font-family: "lato";
  color: #4d6e72 !important;
}

button.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  border: none;
}

.react-calendar__month-view__weekdays__weekday abbr[data-original-title],
abbr[title] {
  text-decoration: none;
}

/* .react-calendar__month-view__weekdays {
  gap: 5px;
}

.react-calendar__month-view__days {
  gap: 1px;
} */

/* Customize the day boxes inside the calendar */
.react-calendar__tile {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 40px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0);
}

/* Customize the appearance of the selected day box */
.react-calendar__tile--active,
.react-calendar__tile:hover {
  /* Your custom styles here */
  background-color: #4d6e72;
  color: #fff;
}

/* Customize the appearance of the day boxes for weekends */
.react-calendar__month-view__days__day--weekend {
  /* Your custom styles here */
  /* background-color: #f8f8f8; */
}

/* Customize the appearance of the day boxes for days that are not in the current month */
.react-calendar__tile--neighboringMonth {
  /* Your custom styles here */
  background-color: #e0e0e0;
}

/* Customize the appearance of the day boxes when hovered */
.react-calendar__tile--hover {
  /* Your custom styles here */
  background-color: #f0f0f0;
}

/* Confirm Modal */
.Modalwrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modalBox {
  height: 250px;
  width: 400px;
  position: fixed;
  top: calc(50% - 125px);
  left: calc(50% - 200px);
  background: white;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.modalBox img {
  margin: 0 auto;
}

.modalBox .btns {
  width: 100%;
}

.modalBox .btns button {
  all: unset;
  width: 100px;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: white;
  cursor: pointer;
  transition: 200ms ease-in;
}

.modalBox .btns button.cancel {
  background: #c1c1c1;
}

.modalBox .btns button.cancel:hover {
  background: grey;
}

.modalBox .btns button.delete {
  background: #e51c23;
  color: white;
  margin-left: 1rem;
}

.modalBox .btns button.delete:hover {
  background: #ff0008;
}

.modalBox .btns .ok {
  border-radius: 0.25rem;
  background: #2b3582;
}

.overlayBG {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000004c;
  z-index: 9999;
}

.overlay {
  position: fixed;
  z-index: 99999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  max-width: 95%;
}

.overlay .close {
  all: unset;
  margin-left: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.overlay .close:hover {
  background: rgb(105, 105, 105);
  transition: 200ms ease;
  border-radius: 50%;
}

.overlay .close:hover i {
  color: white;
}

.backButton {
  width: 90%;
}

.backButton a {
  width: 45px;
  height: 45px;
  border: 1.07143px solid #ffffff;
  background: linear-gradient(180deg, #a2a9e8 0%, #757dc5 100%);
  box-shadow: 0px 4.28571px 4.28571px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.backButton a img {
  width: 17px;
}

.backButton a:hover {
  cursor: pointer;
  background: linear-gradient(180deg, #7e86ca 0%, #626bbb 100%);
  box-shadow: 0px 7.28571px 7.28571px rgba(0, 0, 0, 0.1);
  transition: 200ms ease-in-out;
}

.loginCard p {
  width: 60%;
  text-align: center;
}







.back-btn {
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 10px;
  width: 85px;
  height: 35px;
  border-radius: 30px;
}

.back-btn a {

  border: 1.07143px solid #ffffff;
  background: linear-gradient(180deg, #76989a 0%, #4d6e72 100%);
  box-shadow: 0px 4.28571px 4.28571px rgba(0, 0, 0, 0.1);
  padding: 3px 13px 10px 13px;
  color: #ffffff;

}

.back-btn a:hover {
  background: linear-gradient(180deg, #4d6e72 0%, #76989a 100%);
}

.back-btn img {
  width: 17px;
  margin: 0px 0px 2px 0px;
}

@media only screen and (max-width: 1024px) {
  .back-btn {
    margin: 0px;
    padding: 0px;
    position: relative;
    top: 0px;
    width: 85px;
    height: 35px;
    border-radius: 30px;
  }
}
.Appointments-wrapper {
  /* display: flex;
    justify-content: space-between; */
  width: 100%;
  background: #fafafb;
}

.padd-0 {
  padding: 0px !important;
}

.Appointments {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  background: #fff;
}

.Appointments .AppointmentsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #fafafb;
  min-height: 56px;
  padding: 0 18px;
}

.Appointments .AppointmentsHeader .left h2 {
  color: #444d99;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 10px;
}

.Appointments .AppointmentsHeader .right {
  display: flex;
  justify-content: space-between;
  column-gap: 23px;
}

.Appointments .AppointmentsHeader .right .box-right {
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
}

.Appointments .AppointmentsHeader .right .box-right .icon {
  width: 24px;
  height: 24px;
}

.Appointments .AppointmentsHeader .right .box-right .icon img {
  width: 100%;
  height: 100%;
}

.Appointments .AppointmentsHeader .right .box-right h6 {
  color: #2b3582;
  font-family: "Lato";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  padding-top: 4px;
}

.Appointments-right {
  width: 100%;
  padding: 0px 15px;
}

.calender {
  width: 100%;
  background: #fff;
  height: auto;
  border-radius: 7px;
  margin-top: 1rem;
  padding: 10px;
  box-shadow: 0px 4px 10px 0px #0000000d;
}

.App-container {
  width: 100%;
  background: #fff;
  border: 1px #e2e2eb solid;
  padding: 15px 20px 0px 20px;
}

.input-control {
  background: #ffffff;
  border: 1px #444d99 solid;
  border-radius: 10px;
  padding: 12px 12px;
  color: #444d99;
  width: 100%;
  font-size: 14px;
  font-family: "Inter";
}

.btn-md {
  cursor: pointer;
  transition: all 0.8s ease 0s;
  border: 1px #fff solid;
  border-radius: 10px;
  font-weight: 500;
}

/* .btn-md-blue {
    background: #444d99;
    color: #fff;
    text-align: center;
    font-size: 13.86px;
    line-height: 16.78px;
    font-weight: 600;
    padding: 10px 30px;
    margin-top: 5px;
  }
  
  .btn-md-blue:hover {
    background: #000;
  }*/

.btn-md-light {
  background: #656db5;
  color: #fff;
  text-align: center;
  font-size: 13.86px;
  line-height: 16px;
  font-weight: 600;
  padding: 7px 10px;
}

.btn-md-light:hover {
  background: #000;
}

.width-100 {
  width: 100%;
}

.top-head {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  height: auto;
}

.top-head h2 {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 21.6px;
  margin: 10px 0px 0px 0px;
}

.add-btn {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  color: #fff;
  padding: 5px;
  background: #07c6e0;
  border: none;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-btn:hover {
  background: #444d99;
}

.doctor-left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.doctor-left .name {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #fff !important;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: "Roboto";
  float: left;
}

.sky {
  background: #18abe3;
}

.blue {
  background: #6452ea;
}

.green {
  background: #85c535;
}

.orange {
  background: #fdb310;
}

.pink {
  background: #ff5bf4;
}

.doctor-left h3 {
  color: #000000;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 23px;
  margin: 10px 0px 0px 0px;
}

.doctor-right {
  display: flex;
  justify-content: flex-end;
}

.doctor-right .cal {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 20px 0px;
}

.doctor-right .cal ul {
  list-style: none outside none;
  text-align: left;
}

.doctor-right .cal ul li {
  padding: 0;
  float: left;
  margin-top: 10px;
}

.doctor-right .cal ul li a {
  color: #c8c8c9;
  font-size: 15px;
  line-height: 17.58px;
  list-style: none outside none;
  padding: 10px 0px 10px 15px;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

.doctor-right .cal ul li a:hover {
  text-decoration: none;
  color: #000;
}

.doctor-right .selected {
  text-decoration: none;
  color: #000 !important;
}

.Doctor-Appointment {
  width: 100%;
}

.Doctor-Appointment .time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 18px;
  line-height: 17.58px;
  padding: 70px 0px 10px 15px;
  font-weight: 500;
  font-family: "Lato";
  white-space: nowrap;
}

.box {
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: self-start;
}

.box .box-left {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.box .box-left img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.box .box-left .icon {
  width: 68px;
  height: 68px;
}

.box .box-left .icon img {
  width: 100%;
  height: 100%;
}

.box .box-left h6 {
  color: #8c8c8c;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.box .box-left h3 {
  color: #000;
  font-family: "Lato";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.6px;
  margin: 0px 0px;
  /* white-space: nowrap; */
  padding-right: 15px;
  min-width: 250px;
}

.box .box-left h3 font {
  font-size: 16px;
}

.box .box-left p {
  color: #6b6b6b;
  font-family: "Lato";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 3px 10px 0px 0px;
}

.box .name {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  color: #fff !important;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
}

.boder {
  border-left: 2px #d9d9d9 solid;
  height: 130px;
  width: 3px;
  margin: 0px 17px 0px 0px;
}

.TextWrap {
  white-space: nowrap;
}

.box-width {
  max-width: 15% !important;
}

.box-width-right {
  max-width: 88% !important;
}

.mar-0 {
  margin: 0px !important;
}

.scan-img {
  padding: 0;
}
.scan-img img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .doctor-left {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
  }

  .doctor-right {
    display: flex;
    justify-content: start;
  }

  .doctor-right .cal ul li a {
    padding: 10px 10px 10px 0px;
  }

  .Appointments .AppointmentsHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background: #fafafb;
    min-height: 56px;
    padding: 0 25px;
    flex-direction: column;
  }

  .add-btn {
    width: 35px;
    height: 35px;
  }

  .doctor-left .name {
    width: 36px;
    height: 37px;
    font-size: 12px;
    margin-right: 5px;
  }

  .box-width {
    max-width: 100% !important;
  }

  .box-width-right {
    max-width: 100% !important;
  }

  .box {
    border-radius: 10px;
    border: 1px solid #e8e8e8;
    background: #fff;
    padding: 1rem;
    display: flex;
    margin-bottom: 20px;
    align-items: start;
    flex-direction: column;
  }

  .boder {
    border-left: 2px #d9d9d9 solid;
    height: 130px;
    width: 3px;
    display: none;
  }

  .box .box-left {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }

  .containt-mar {
    margin: 15px 0px 0px 65px;
  }

  .Doctor-Appointment .time {
    font-size: 18px;
    line-height: 17.58px;
    padding: 0px 0px 10px 15px;
  }

  .padd-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.checkbox-switch {
  /* transform: scale(1.5); */
  width: 60px !important;
  height: 25px !important;
  background: linear-gradient(180deg, #62e587 0%, #379652 100%);
  /* linear-gradient(0deg, #3b9d57, #3b9d57), */

  border: 2.5px solid rgb(87, 182, 87);

  border-radius: 22.1731px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72);
  transition: 300ms ease-in-out;
}

.checkbox--on {
  flex-direction: row;
  background: linear-gradient(180deg, #e56262 0%, #d21f1f 100%);
  /* linear-gradient(0deg, #871212, #871212), */
  border: 1.23184px solid #861212;
  color: white;
  transition: 300ms ease-in-out;
}

.checkbox--on .checkbox__ball {
  width: 21.97px;
  height: 22.15px;
  background: #ffffff;
  box-shadow: 0px 2.86105px 2.86105px rgba(0, 0, 0, 0.1);
  transition: 300ms ease-in-out;
}

.checkbox__ball {
  background: whitesmoke;
  box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72);
  width: 20px;
  height: 100%;
  border-radius: 50%;
  transition: 300ms ease-in-out;
}

/* Confirm Modal */
.Modalwrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modalBox {
  height: 250px;
  width: 400px;
  position: fixed;
  top: calc(50% - 125px);
  left: calc(50% - 200px);
  background: white;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modalBox img {
  margin: 0 auto;
}

.modalBox .btns {
  width: 100%;
}

.modalBox .btns button {
  all: unset;
  width: 100px;
  padding: 7px 15px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: white;
  cursor: pointer;
  transition: 200ms ease-in;
}

.modalBox .btns button.cancel {
  background: #c1c1c1;
}

.modalBox .btns button.cancel:hover {
  background: grey;
}

.modalBox .btns button.delete {
  background: #e51c23;
  color: white;
  margin-left: 1rem;
}

.modalBox .btns button.delete:hover {
  background: #ff0008;
}

.modalBox .btns .ok {
  border-radius: 0.25rem;
  background: #2b3582;
}

.overlayBG {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #0000004c;
  z-index: 9999;
}

.overlay {
  position: fixed;
  z-index: 99999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  max-width: 95%;
  animation: fadeIn 0.2s;
}

.overlay .close {
  all: unset;
  margin-left: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.overlay .close:hover {
  background: rgb(105, 105, 105);
  transition: 200ms ease;
  border-radius: 50%;
}

.overlay .close:hover i {
  color: white;
}

/* Loader CSS */

.loading-area {
  display: grid;
  place-items: center;
  height: 100vh;
}
.loader div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  transform: scale(0);
  animation: animate 1.5s ease-in-out infinite;
  display: inline-block;
  margin: 0.5rem;
}
.loader div:nth-child(0) {
  animation-delay: 0s;
}
.loader div:nth-child(1) {
  animation-delay: 0.2s;
}
.loader div:nth-child(2) {
  animation-delay: 0.4s;
}
.loader div:nth-child(3) {
  animation-delay: 0.6s;
}
.loader div:nth-child(4) {
  animation-delay: 0.8s;
}
.loader div:nth-child(5) {
  animation-delay: 1s;
}
.loader div:nth-child(6) {
  animation-delay: 1.2s;
}
.loader div:nth-child(7) {
  animation-delay: 1.4s;
}
@keyframes animate {
  0%,
  100% {
    transform: scale(0.2);
    background-color: #4d6e72;
  }
  40% {
    transform: scale(1);
    background-color: #4d6e72a9;
  }
  50% {
    transform: scale(1);
    background-color: #4d6e724b;
  }
}

.error-msg {
  color: red;
  font-size: 14px;
}

label span {
  color: red;
}

.wrapper {
  display: flex;
}

#root {
  background: white;
}

/* monazir */

/* CSS to disable buttons */

.disable button,
.disable .ql-picker {
  pointer-events: none;
  background-color: transparent;
  /* You can also adjust the opacity for a more or less faded appearance */
  /* opacity: 0.5; */
}

/* Optional: To change the cursor style when hovering over disabled buttons */
.disable button:hover {
  cursor: not-allowed;
}

.nodata {
  color: grey;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 2rem;
  position: absolute;
  width: calc(100% - 100px);
}

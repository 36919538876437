

.mar-20 {
  margin-top: 20px;
}

.mar-30 {
  margin-top: 30px;
}

.mar-40 {
  margin-top: 40px;
}

.mar-50 {
  margin-top: 50px;
}

.mar-100 {
  margin-top: 100px;
}

.mar-150 {
  margin-top: 200px;
}

.p-lr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
/* .LoginMainBg{
  position: fixed;
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(124,158,160,0.9); 
  z-index: 0;
  cursor: pointer;
}  */

.main-bg {
  background-image: url(/public/images/main-bg.png); 
  position: relative;
  min-height: auto;
  background-size: cover;
  background-position: center center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  padding:0px;
  height: 100vh; 
}

.BgOverly{
  background-image: url(/public/images/reflight.png); 
  position: fixed;
   top: 0; 
   bottom: 0;
   width: 100%;  
}


.TopBox {
  background: rgba(77, 110, 114, 0.8);
  padding: 0px;
  height:50px;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
}

.TopLogPadding{
  padding: 5px 0px 0px 0px;
}
/* 
.TopLogPadding img{
  transform: scale(0.8);
 
} */


.LoginMainBox{
  width: 66%;
  margin: auto;
  margin-top: 4%;
}







.left-box {
  background-image: url(/public/images/LeftBg.png); 
  background-size: cover;
  background-position: center center;
   padding: 100px;
  text-align: center;
  border-radius: 21px 0px 0px 21px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
}

.left-box img {
  max-width: 100%;
}

.right-box {
  padding: 70px 40px 47px 40px;
  border-radius: 0px 21px 21px 0px;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(rgba(160, 177, 178, 0.7), rgba(209, 217, 218, 0.9),rgba(160, 177, 178, 0.7));
  
}

.right-box img {
  max-width: 100%;
}

.right-box h2 {
  font-size: 29.59px;
  color: #31595E;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
}

.right-box p {
  font-size: 19.2px;
  margin-top: 10px;
  position: relative;
  color: #000;
  line-height: 25px;
  text-align: center;
  margin-bottom: 20px;
}

.right-box h4 {
  font-size: 15.2px;
  margin-top: 10px;
  position: relative;
  color: #000000;
  line-height: 25px;
  text-align: center;
  margin-bottom: 20px;
}

.right-box h3 {
  font-size:24px;
  margin-top: 10px;
  position: relative;
  color: #fff;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}

.right-box h4 span a {
  color: #1C4146;
}

.right-box h4 span:hover {
  color: #4d6e72;
}

.checkbox-taxt {
  font-size: 15;
  color: #1C4146 !important;
  line-height: 21px !important;
  margin-bottom: 5px !important;
  font-weight: 400 !important;
  font-size: 15.11px !important;
  margin-top: 12px;
  padding-left: 5px;
  position: absolute;
}

.inputField {
  margin-bottom: 10.57px !important;
  position: relative;
}

.inputField > input {
  background: #ffffff;
  border: 1px #759799 solid;
  border-radius: 4px;
  min-height: 43.14px;
  color: #000;
  font-size: 18.13px;
  padding: 11px 8px 11px 8px;
  font-weight: 400;
  width: 100%;
}

.inputField > button[type="submit"],
.inputField > a {
  cursor: pointer;
  transition: all 0.8s ease 0s;
  padding: 11px 8px 11px 8px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  font-weight: 400;
  margin: -9px 0px 0px 0px;
  border: none;
  text-align: center;
  display: block;
}

.inputField > button[type="submit"]:hover {
  background: #4d6e72;
  transition: all 0.3s ease 0s;
}

.inputField > label {
  font-size: 18.3px;
  color: #31595E;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 10px;
}



.inputField > input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17.1535px;
  line-height: 21px;
  color: rgba(115, 124, 195, 0.55);
}

.checkbox {
  width: 17px !important;
  height: 17px;
}

.width-100 {
  width: 100%;
}

.btn-md-bg {
  background: #678b8e;
  background-image: linear-gradient(#678b8e, #527377);
  color: #fff;
}

.btn-md-bg-back {
  background: #ffffff;
  color: #b2b7de;
  border: 1px #b2b7de solid !important;
}

.eye-img {
  position: absolute;
  right: 12px;
  margin-top: 15px;
  width: 20px;
  height: 20px;
}

.text-left {
  text-align: left !important;
}

.btn-back-top {
  background-image: linear-gradient(#8a92d6, #4f58a1);
  color: #b2b7de;
  border: 1px #b2b7de solid !important;
  width: 85px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  margin: -40px 0px 30px 0px;
}

.btn-back-top a {
  color: #fff;
  font-family: "Roboto";
}

.backButton {
  width: 90%;
}

.backButton a {
  width: 45px;
  height: 45px;
  border: 1.07143px solid #ffffff;
  background: linear-gradient(180deg, #a2a9e8 0%, #757dc5 100%);
  box-shadow: 0px 4.28571px 4.28571px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.backButton a img {
  width: 17px;
}

.backButton a:hover {
  cursor: pointer;
  background: linear-gradient(180deg, #7e86ca 0%, #626bbb 100%);
  box-shadow: 0px 7.28571px 7.28571px rgba(0, 0, 0, 0.1);
  transition: 200ms ease-in-out;
}

.loginCard p {
  width: 60%;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .LoginMainBox {
    width: 95%;
    margin: auto;
    margin-top: 50px;
}
  .main-bg {
    padding: 0px;
    height: 100%;
  }

  .left-box {
    padding: 15px;
    text-align: center;
    border-radius: 0px 0px 21px 21px;
    height: 100%;
  }

  .right-box {
    /* background: rgba(255, 255, 255, 0.6); */
    padding: 15px 15px 15px 15px;
    border-radius: 21px 21px 0px 0px;
    height: 100%;
  }
}

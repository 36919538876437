/*----------------------------------------------------*/
body {
  line-height: 26px;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #000;
}

.list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

.doctor-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.padd-0 {
  padding: 0px !important;
}

.doctor-wrapper {
  background: white;
  position: relative;
}

.doctors {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e4e4e4;
  background: #fff;
}

.mid-head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.mid-head h2 {
  color: #2b3582;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 21px;
  margin: 10px 0px 0px 0px;
}

.btn-md {
  cursor: pointer;
  transition: all 0.8s ease 0s;
  border: 1px #fff solid;
  border-radius: 10px;
  font-weight: 500;
}

.btn-md-blue {
  background: #444d99;
  color: #fff;
  text-align: center;
  font-size: 13.86px;
  line-height: 16.78px;
  font-weight: 600;
  padding: 13px 15px;
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.btn-md-blue:hover {
  background: #000;
}

.btn-md-blue img {
  margin-right: 5px;
}

.btn-small {
  cursor: pointer;
  transition: all 0.8s ease 0s;
  border: 1px #fff solid;
  border-radius: 10px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: 13.86px;
  line-height: 16.78px;
  font-weight: 600;
  padding: 8px 7px;
  margin-top: 0px;
  margin-right: 5px;
  height: 36px;
  width: 36px;
}

.greenbg {
  background-image: linear-gradient(#5ede82, #3a9c56);
}

.yellowbg {
  background-image: linear-gradient(#ffc200, #ffc200);
}

.redbg {
  background-image: linear-gradient(#f36c6c, #d72a2a);
}

.btn-small:hover {
  background-image: linear-gradient(#444d99, #444d99);
}

.input-control {
  background: #ffffff;
  border: 1px #cdcdcd solid;
  border-radius: 10px;
  padding: 12px 12px;
  color: #444d99;
  width: 100%;
  font-size: 14px;
  font-family: "Inter";
}

.input-control::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2px;
  color: #cecece;
}

.blue {
  color: #6253d1 !important;
}

.pagination-col {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.pagination-col .lefttext p {
  font-size: 16px;
  color: #808080;
}

.pagination {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.pagination ul {
  list-style: none outside none;
}

.pagination ul li {
  float: left;
  margin-left: 10px;
}

.pagination ul li a {
  color: #444d99;
  display: block;
  float: left;
  font-size: 16px;
  line-height: 32px;
  list-style: none outside none;
  padding: 0px 0px 0 0px;
  text-decoration: none;
  font-weight: normal;
  text-align: center;
  width: 38px;
  height: 35px;
  border-radius: 4px;
  border: 1px #444d99 solid !important;
}

.pagination ul li a:hover {
  color: #fff;
  text-decoration: none;
  background: #444d99;
}

.pagination ul li a.active {
  color: #fff;
  text-decoration: none;
  background: #444d99;
}

.pagination ul li img {
  margin: 10px 6px;
  cursor: pointer;
}

.cardhead {
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: white;
  overflow: hidden;
}

.cardhead .Add-form-group {
  padding: 1rem;
}

.cardhead > .heading {
  background: #7c9ea0;
  width: 100%;
  padding: 5px 10px;
}

.cardhead > .heading h2 {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.238px;
  line-height: 21px;
  color: white;
  margin-top: 5px;
}

.cardhead .form-group {
  padding-right: 1rem;
}

.leftbar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
